<template>
  <b-container>
    <div v-if="loading" class="loading">読み込み中...</div>
    <b-alert :show="!!error" variant="danger">
      {{ error ? (error.data || error.statusText || error.status || error) : '' }}
    </b-alert>
    
    <b-form v-if="!loading && !updated" class="inputForm" @submit.stop.prevent>
      <b-row class="my-4">
        <b-col class="h4 text-center">{{ corporationName }} 様</b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col class="text-center">メンバー情報の仮登録を行います。<br/>氏名、送信先を入力後、「登録を依頼する」ボタンをクリックしてください。</b-col>
      </b-row>
      
      <b-form-group class="my-2" label-cols-sm="3" label="氏名（漢字）" label-for="userName">
        <b-input id="userName" type="text" v-model.trim="user.user_name" :autofocus="true"/>
      </b-form-group>
      <b-row v-if="$v.user.user_name.$error">
        <b-col offset-sm="3">
          <div class="error" v-if="!$v.user.user_name.required">氏名（漢字）を入力してください</div>
        </b-col>
      </b-row>

      <b-row class="mt-4 mb-3">
        <b-col offset-sm="3" class="text-center">
          <span>＜メール送信先＞</span>
        </b-col>
      </b-row>

      <b-form-group class="my-2" label-cols-sm="3" label="メールアドレス" label-for="mailAddr">
        <b-input id="mailAddr" type="text" v-model.trim="user.mail_address" :placeholder="(useMobile ? '省略可' : '')"/>
      </b-form-group>
      <b-row v-if="$v.user.mail_address.$error">
        <b-col offset-sm="3">
          <div class="error" v-if="!$v.user.mail_address.required">メールアドレスまたはSMSを入力してください</div>
          <div class="error" v-if="!$v.user.mail_address.email">メールアドレスの形式が不正です</div>
        </b-col>
      </b-row>

      <b-form-group class="my-2" label-cols-sm="3" label="サブメールアドレス１" label-for="subMailAddr1">
        <b-input id="subMailAddr1" type="text" v-model.trim="user.sub_mail_address1" placeholder="省略可"/>
      </b-form-group>
      <b-row v-if="$v.user.sub_mail_address1.$error">
        <b-col offset-sm="3">
          <div class="error" v-if="!$v.user.sub_mail_address1.email">メールアドレスの形式が不正です</div>
        </b-col>
      </b-row>

      <b-form-group class="my-2" label-cols-sm="3" label="サブメールアドレス２" label-for="subMailAddr2">
        <b-input id="subMailAddr2" type="text" v-model.trim="user.sub_mail_address2" placeholder="省略可"/>
      </b-form-group>
      <b-row v-if="$v.user.sub_mail_address2.$error">
        <b-col offset-sm="3">
          <div class="error" v-if="!$v.user.sub_mail_address2.email">メールアドレスの形式が不正です</div>
        </b-col>
      </b-row>

      <b-form-group class="my-2" label-cols-sm="3" label="SMS（電話番号）" label-for="phoneNo">
        <b-input id="phoneNo" type="text" v-model.trim="user.phone_no" placeholder="省略可"/>
      </b-form-group>
      <b-row v-if="$v.user.phone_no.$error">
        <b-col offset-sm="3">
          <div class="error" v-if="user.phone_no && $v.user.phone_no.$error">11桁の数値を入力してください</div>
        </b-col>
      </b-row>

      <b-row class="my-5 text-center" :show="loading && !error">
        <b-col>
          <b-button id="insertButton" variant="primary" @click="createData">
            <b-spinner small v-if="sending.create"></b-spinner>登録を依頼する
          </b-button>
        </b-col>
      </b-row>

      <v-tour name="helpTour" class="helpTour" :steps="dynamicSteps()" :options="tourOptions"></v-tour>
      <b-button variant="warning" class="help"  @click="$tours['helpTour'].start()">
        <b-icon-chat-square-text class="helpIcon"/><span class="helpCaption ml-2">ヘルプ</span>
      </b-button>
    </b-form>

    <b-row class="my-5" v-if="updated">
      <b-col class="text-center">
        <div id="completeMessage">
          <span class="lead">登録依頼を送信しました。</span><br>
          <br>
          ＜お願い＞<br>
          本システムからのメールは <code>{{ corporationMailAddress }}</code> から送信されます。<br>
          このアドレスからのメールを受信できるように設定をお願いします。
        </div>
      </b-col>
    </b-row>

  </b-container>

</template>

<script>
import { required, requiredIf, minLength, maxLength, numeric, email } from 'vuelidate/lib/validators'

export default {
  name: 'ViewUser',
  props: ['corpId', 'corpToken'],
  data () {
    return {
      loading: false,
      sending: false,
      updated: false,
      corporationName: null,
      corporationMailAddress: null,
      useMobile: false,
      user: {},
      error: null,
      tourOptions: {
        useKeyboardNavigation: true,
        labels: { buttonSkip: '中断', buttonPrevious: '← 前', buttonNext: '次 →', buttonStop: '終了' }
      }
    }
  },
  validations: {
    user: {
      user_name: {
        required
      },
      mail_address: {
        required: requiredIf(function(user) {
          return !this.useMobile && !user.phone_no
        }),
        email
      },
      sub_mail_address1: {
        email
      },
      sub_mail_address2: {
        email
      },
      phone_no: {
        required: requiredIf(function(user) {
          return !this.useMobile && !user.mail_address
        }),
        numeric,
        minLength: minLength(11),
        maxLength: maxLength(11)
      }
    }
  },
  created () {
    this.fetchData()
  },
  destroyed () {
    this.$tours['helpTour'].stop()
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    dynamicSteps () {
      const DEFAULT_STEP_PARAMS = { placement: 'top', highlight: false, enableScrolling: false }
      let steps = []
      steps = steps.concat([
        {
          target: '#userName',
          content: '氏名を入力してください。',
          params: DEFAULT_STEP_PARAMS
        },
        {
          target: '#mailAddr',
          content: (this.useMobile ? '' : 'メールアドレスもしくはSMSの指定が必須です。<br>') + 'メールアドレスは３つまで登録可能です。',
          params: DEFAULT_STEP_PARAMS
        },
        {
          target: '#phoneNo',
          content: '必要に応じてショートメールの送信先（電話番号）<br>を入力してください。',
          params: DEFAULT_STEP_PARAMS
        },
        {
          target: '#insertButton',
          content: '登録を依頼するボタンをクリックして完了です。<br>エラーがある場合、項目下に赤字で表示されます。',
          params: { ...DEFAULT_STEP_PARAMS, enableScrolling: true }
        }        
      ])
      return steps
    },

    fetchData () {
      this.loading = true
      this.error = null
      
      const params = {
        'corporation_id': this.corpId, 
        'id_token': this.corpToken
      }
      this.$store.dispatch('http/get', { apiName: 'canaryPubViewUser', params: params }, { root: true })
        .then(res => {
          if (res.data && res.data['corporation_id']) {
            const info = res.data
            this.corporationName = info['corporation_name']
            this.corporationMailAddress = info['mail_address']
            this.useMobile = info['use_mobile']
            this.loading = false
          } else {
            this.error = '無効なアドレスです。'
          }
        })
        .catch(res => {
          this.error = res.response
        })
    },

    createData () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      this.msgBoxConfirm('入力した内容で登録を依頼します。よろしいですか？', {okVariant: 'primary', autoFocusButton: 'ok'})
        .then(val => {
          if (val) {
            const params = this.user
            params['corporation_id'] = this.corpId
            params['temp_user_id'] = this.$store.getters['app/generateUuid']()

            this.sending = true
            this.$store.dispatch('http/post', { apiName: 'canaryPubViewUser', data: params }, { root: true })
              .then(() => {
                this.updated = true
                const h = this.$createElement
                const messageVNode = h('div', { class: ['text-center'] }, [
                  h('b-icon', { props: { icon: 'check-circle', fontScale: 4 }, style: { color: 'mediumseagreen', animation: 'pulse 2s infinite' } }),
                  h('p', { class: ['text-center'], style: { fontSize: '1.2rem', margin: '10px 0 0' }}, '登録依頼を送信しました')
                ])          
                this.msgBoxOk(messageVNode)
              })
              .catch(res => {
                this.error = res.response
              })
          }
        })
    }
  }

}
</script>
