<template>
  <b-container>
    <div v-if="loading" class="loading">読み込み中...</div>
    <b-alert :show="!!error" class="error" variant="danger">
      {{ error ? (error.data || error.statusText || error.status || error) : '' }}
    </b-alert>

    <div v-if="!loading" class="pt-3">
      <b-row class="headerAttr my-1">
        <b-col sm="2" offset-sm="3">
          <span>氏名：</span>
        </b-col>
        <b-col sm="4">
          <span class="ml-3">{{ userName }}</span>
        </b-col>
      </b-row>
      <b-row class="headerAttr my-1">
        <b-col sm="2" offset-sm="3">
          <span>回答日時：</span>
        </b-col>
        <b-col sm="4">
          <span class="ml-3">{{ questionnaire_result.response_date }} {{ questionnaire_result.response_time }}</span>
        </b-col>
      </b-row>

      <b-row class="my-4 text-center">
        <b-col>＜確認項目＞</b-col>
      </b-row>

      <b-row align-h="center" v-for="item in questionnaire_result.response_item" :key="item.question">
        <b-col md="9">
          <b-card align="left" class="questionCard my-2" header-tag="header">
            <template v-slot:header>
              <span class="mr-2">質問：</span>
              {{ item.question }}
            </template>
            <div>
              <span class="mr-2">回答：</span>
              <template v-if="qHandler.isTypeCheck(item)">
                <div v-for="(selected, index) in qHandler.sortedSelectedList(item)" :key="index" class="pl-3">
                  {{ selected || item.otherText }}
                </div>
              </template>
              <template v-else>
                {{ qHandler.toDispChoiceText(item) }}
              </template>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="my-5 text-center">
        <b-col>
          <div>
            <b-button id="backButton" variant="secondary" @click="historyBack()">集計結果に戻る</b-button>
          </div>
        </b-col>
      </b-row>

    </div>
  </b-container>
</template>

<script>
import { default as qHandler } from '@/const/questionnaireHandler'

export default {
  name: 'ViewResponseDetail',
  props: ['corpId', 'ruid', 'secretToken'],
  data () {
    return {
      loading: false,
      userName: '',
      questionnaire_result: {},
      error: null,
      qHandler
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {
      this.loading = true
      this.error = null

      const params = {
        'corporation_id': this.corpId,
        'ruid': this.ruid,
        'secret_token': this.secretToken
      }
      this.$store.dispatch('http/get', { apiName: 'canaryPubViewSummary', params: params }, { root: true })
        .then(res => {
          this.loading = false
          if (res.data) {
            this.questionnaire_result = res.data
            this.userName = res.data.user_name
          } else {
            this.error = 'データ取得に失敗しました'
          }
        })
        .catch(res => {
          this.questionnaire_result = {}
          this.error = res.response
        })
    },

    historyBack() {
      this.$router.go(-1)
    }
  }
}
</script>