<template>
  <div>
    <b-row id="responseStats" class="mt-3 mb-2 flex-nowrap text-center justify-content-md-center">
      <b-col md="3">
        <div class="count-caption-lg">回答</div>
        <span class="count-lg" role="button" @click="tabIndex = 1">{{ summary.summary_count.responsed }}</span>
      </b-col>
      <b-col md="3">
        <div class="count-caption-lg">未回答</div>
        <span class="count-lg" role="button" @click="tabIndex = 1">{{ totalUnresponse }}</span>
      </b-col>
    </b-row>

    <b-row align-h="center" class="mt-4">
      <b-col lg="9">
        <b-tabs v-model="tabIndex" content-class="mt-4 mx-2" active-nav-item-class="font-weight-bold" @input="tabSelected" justified>
          
          <b-tab id="responseItems" title="回答内容" title-item-class="text-nowrap">
            <b-card align="center" class="m-4" v-if="(summary.summary_item || []).length == 0">
                <b-container>{{ summary.summary_item ? "質問なし" : "回答なし" }}</b-container>
            </b-card>
            <b-row align-h="center" v-for="item in summary.summary_item" :key="item.question" header-tag="header">
              <b-col>
                <b-card align="left" class="my-2">
                  <template v-slot:header>
                    <b-row>
                      <b-col sm="8" md="9" class="m-auto">
                        {{ item.question }}
                        <b-badge v-if="item.required" pill variant="info" class="attrBadge ml-2">必須</b-badge>
                        <b-badge v-if="qHandler.isTypeCheck(item)" pill variant="info" class="attrBadge ml-2">複数可</b-badge>
                      </b-col>
                      <b-col sm="4" md="3" class="text-nowrap text-right">
                        <b-button class="responseMembers mt-2 mt-sm-0" v-if="showDetail" size="sm" :variant="(collapse[item.question] ? 'secondary' : 'outline-secondary')"
                            @click="toggleCollapse(item)">
                          <b-icon icon="people-fill" class="mr-1"></b-icon>回答者
                        </b-button>
                      </b-col>
                    </b-row>
                  </template>
                  <b-container v-for="(count, key) in item.choice" :key="key" class="rounded border mb-1">
                    <b-row class="p-2" @click="toggleCollapse(item)" role="button">
                      <b-col sm="8" md="9">
                        <!-- 選択肢／回答内容 -->
                        <span>{{ key }}</span>
                        <!-- 回答率を棒グラフ表示（記述式は非表示） -->
                        <b-progress v-if="!qHandler.isTypeText(item)" class="my-1" height="8px" :max="summary.summary_count.total">
                          <b-progress-bar class="c-theme" :value="count"></b-progress-bar>
                        </b-progress>
                      </b-col>
                      <b-col sm="4" md="3" class="text-nowrap pr-2">
                        <!-- 回答人数 -->
                        <span class="mr-3">{{ count }}名</span>
                        <!-- 回答率を表示（記述式は非表示） -->
                        <span v-if="!qHandler.isTypeText(item)">({{ (count / summary.summary_count.total) | toParcentage }}) </span>
                      </b-col>
                    </b-row>
                    <b-row class="members px-3" v-if="showDetail" v-show="collapse[item.question]">
                      <span v-for="(user, key) in item.choice_user[key]" :key="key" class="memberLink">
                        <a @click="$emit('detail-click', user.ruid )">
                          {{ user.user_name }}
                        </a>
                      </span>
                    </b-row>
                  </b-container>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab id="responseMembers" title="回答者・未回答者" v-if="showDetail" title-item-class="text-nowrap">
            <b-card class="members my-4 text-center" body-class="text-left" header="回答者">
              <span v-for="(user, key) in summary.summary_user.responsed" :key="key" class="memberLink">
                <a @click="$emit('detail-click', user.ruid)">
                  {{ user.user_name }}
                </a>
              </span>
            </b-card>
            <b-card class="members my-4 text-center" body-class="text-left" header="未回答者">
              <span v-for="(user, key) in summary.summary_user.not_responsed" :key="key" class="memberLink">
                <b-icon-exclamation-triangle v-if="user.unsend" variant="danger" v-b-popover.hover.top="'配信先なし'" class="mr-1"/>
                <b-icon-exclamation-triangle-fill v-if="!user.unsend && user.undelivery" variant="warning" v-b-popover.hover.top="'配信失敗あり'" class="mr-1"/>
                <a @click="$emit('detail-click', user.ruid )">{{ user.user_name }}</a>
              </span>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  
  </div>
</template>

<script>
import { default as qHandler } from '@/const/questionnaireHandler'

export default {
  name: 'ResponseSummaryItems',
  props: ['summary', 'detail'],
  data () {
    return {
      collapse: {},
      tabIndex: 0, 
      qHandler
    }
  },
  created () {
    if (this.summary == null) {
      this.summary = {
        summary_user: {responsed: [], not_responsed: []},
        summary_count: {total: 0, responsed: 0},
        summary_item: []
      }
    }
    // 履歴情報より画面状態を復元
    const currentState = history.state
    if (currentState && currentState.collapse) {
      this.tabIndex = currentState.tabIndex
      this.collapse = currentState.collapse
    } else {
      this.tabIndex = 0
      // 折り畳み状態初期化（data連携のため$setを使用）
      this.collapse = {}
      if (this.summary.summary_item) {
        this.summary.summary_item.forEach((v) => {this.$set(this.collapse, v.question, false)})
      }
    }
  },
  computed: {
    totalUnresponse() {
      return this.summary.summary_count.total - this.summary.summary_count.responsed
    },
    showDetail() {
      return this.detail || this.summary.disclosure == 2
    }
  },
  filters: {
    toParcentage (value) {
      return (parseFloat(value) * 100).toFixed(1) + '%' 
    }
  },
  methods: {
    toggleCollapse (item) {
      this.collapse[item.question] = !this.collapse[item.question]
      window.history.replaceState({'tabIndex': this.tabIndex, 'collapse': this.collapse}, null, window.location.href)
    },
    tabSelected (tabIndex) {
      window.history.replaceState({'tabIndex': tabIndex, 'collapse': this.collapse}, null, window.location.href)
    }
  }

}
</script>

<style scoped>
.count-caption-lg {
  font-size: 1.8rem;
}
.count-lg {
  font-size: 3rem;
}
[role=button] {
  cursor: pointer;  
}
.members a {
  cursor: pointer;
  color: #007bff;
  display: inline-block; /* for no-wrap */
  text-decoration-line: underline;
  text-decoration-color: #007bff;
}
.members a:hover {
  text-decoration-color: inherit;
}
.memberLink {
  margin-bottom: 0.5rem;
  display: inline-block;
}
.memberLink:not(:last-child) {
  margin-right: 1rem;
}
</style>