<template>
  <b-container>
    <div v-if="loading" class="loading">読み込み中...</div>
    <b-alert :show="!!error" class="error" variant="danger">
      {{ error ? (error.data || error.statusText || error.status || error) : '' }}
    </b-alert>
    
    <div v-if="!loading" class="pt-3">
      <b-row class="headerAttr my-2">
        <b-col md="2" offset-md="3">
          <span>件名：</span>
        </b-col>
        <b-col md="7">
          <span class="ml-3">{{ summary.subject }}</span>
        </b-col>
      </b-row>
      <b-row class="headerAttr my-2">
        <b-col md="2" offset-md="3">
          <span>送信日時：</span>
        </b-col>
        <b-col md="7">
          <span class="ml-3">{{ summary.create_date }} {{ summary.create_time }}</span>
        </b-col>
      </b-row>
      <b-row class="headerAttr my-2">
        <b-col md="2" offset-md="3">
          <span>配信先：</span>
        </b-col>
        <b-col md="7">
          <span class="ml-3">{{ summary.summary_count.total }}名</span>
        </b-col>
      </b-row>
      <b-row class="headerAttr my-2">
        <b-col md="2" offset-md="3">
          <span>公開範囲：</span>
        </b-col>
        <b-col md="7">
          <span class="caution ml-3">{{ toDisclosureName(summary.disclosure) }}</span>
        </b-col>
      </b-row>

      <ResponseSummaryItems :summary.sync="summary" @detail-click="moveToDetail"/>

      <b-row class="mt-5">
        <b-col class="text-center">
          <div>
            <b-button variant="outline-secondary" class="min-w-25" v-if="isWindowClosable()" @click="windowClose">閉じる</b-button>
          </div>
        </b-col>
      </b-row>

    </div>
  </b-container>
</template>

<script>
import ResponseSummaryItems from '@/components/ResponseSummaryItems.vue'

export default {
  name: 'ViewResponseSummary',
  props: ['corpId', 'questionnaireId', 'secretToken'],
  components: {
    ResponseSummaryItems
  },
  data () {
    return {
      loading: false,
      summary: null,
      error: null
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    '$route': 'fetchData'
  },
  filters: {
    toParcentage (value) {
      return (parseFloat(value) * 100).toFixed(1) + '%' 
    }
  },
  methods: {
    toDisclosureName(val) {
      if (val === 1) {
        return '集計数のみ'
      } else if (val === 2) {
        return '全て'
      } 
      return ''
    },

    fetchData () {
      this.loading = true
      this.error = null

      const params = {
        'corporation_id': this.corpId,
        'questionnaire_id': this.questionnaireId,
        'secret_token': this.secretToken
      }
      this.$store.dispatch('http/get', { apiName: 'canaryPubViewSummary', params: params }, { root: true })
        .then(res => {
          this.loading = false
          if (res.data) {
            this.summary = res.data
          } else {
            this.error = 'データ取得に失敗しました'
          }
        })
        .catch(res => {
          this.summary = null
          this.error = res.response
        })
    },

    moveToDetail (ruid) {
      this.$router.push({ name: 'ViewResponseDetail', params: { corporationId: this.corpId, ruid: ruid, secretToken: this.secretToken }})
    },

    isWindowClosable() {
      return window.opener || window === window.top && history.length <= 1
    },

    windowClose () {
      window.close()
    }
  }

}
</script>