<template>
  <b-container>
    <div v-if="loading" class="loading">読み込み中...</div>
    <b-alert :show="!!error" class="error" variant="danger">
      {{ error ? (error.data || error.statusText || error.status || error) : '' }}
    </b-alert>

    <div v-if="!loading" class="inputForm">
      <b-row class="mb-1">
        <b-col class="text-center">
          <label id="userName">{{ userName }}</label> さん
        </b-col>
      </b-row>
      
      <div v-if="!responsed">
        <div v-if="questionnaire.confirm_item.length > 0">
          <b-row align-h="center" v-for="(item, item_index) in questionnaire.confirm_item" :key="item.question">
            <b-card align="left" class="w-100 my-3 questionCard" header-tag="header">
              <template v-slot:header>
                <span class="mr-2">質問：</span>
                {{ item.question }}
                <b-badge v-if="!confirm && item.required" pill variant="info" class="attrBadge ml-2">必須</b-badge>
                <b-badge v-if="!confirm && qHandler.isTypeCheck(item)" pill variant="info" class="attrBadge ml-2">複数可</b-badge>
              </template>

              <!--回答入力時 -->
              <div v-if="!confirm">
                <b-row v-if="$v.questionnaire.confirm_item.$each[item_index].$error">
                  <b-col>
                    <div class="error" v-if="!$v.questionnaire.confirm_item.$each[item_index].selected.required">回答を選択してください</div>
                    <div class="error" v-if="!$v.questionnaire.confirm_item.$each[item_index].otherText.required">テキストを入力してください</div>
                    <div class="error" v-if="!$v.questionnaire.confirm_item.$each[item_index].otherText.maxLength">
                      テキスト入力は{{$v.questionnaire.confirm_item.$each[item_index].otherText.$params.maxLength.max}}文字以下としてください</div>
                  </b-col>
                </b-row>
                <!-- 単一選択 -->
                <template v-if="qHandler.isTypeRadio(item)">
                  <div v-for="(choice, index) in item.choice" :key="index" class="choiceItem">
                    <b-radio :value="choice" v-model="item.selected" class="w-100">{{ choice }}</b-radio>
                  </div>
                  <div class="choiceItem" v-if="item.choiceOther">
                    <b-input-group>
                      <b-radio class="mr-4" value="" v-if="item.choice.length > 0" v-model="item.selected">その他</b-radio>
                      <b-input class="otherText" type="text" v-model.trim="item.otherText"
                        :disabled="item.choice.length > 0 && item.selected!=''" />
                    </b-input-group>
                  </div>
                </template>
                <!-- 複数選択 -->
                <template v-if="qHandler.isTypeCheck(item)">
                  <b-checkbox-group v-model="item.selected">
                    <div v-for="(choice, index) in item.choice" :key="index" class="choiceItem">
                      <b-checkbox :value="choice" v-model="item.selected" class="w-100">{{ choice }}</b-checkbox>
                    </div>
                    <div class="choiceItem" v-if="item.choiceOther">
                      <b-input-group>
                        <b-checkbox class="mr-4" value="" v-if="item.choice.length > 0" v-model="item.selected">その他</b-checkbox>
                        <b-input class="otherText" type="text" v-model.trim="item.otherText"
                          :disabled="item.choice.length > 0 && (!Array.isArray(item.selected) || !item.selected.includes(''))" />
                      </b-input-group>
                    </div>
                  </b-checkbox-group>
                </template>
                <!-- テキスト -->
                <template v-if="qHandler.isTypeText(item)">
                  <div class="choiceItem">
                    <b-input-group>
                      <b-textarea class="otherText" rows="2" max-rows="3" v-model.trim="item.otherText"/>
                    </b-input-group>
                  </div>
                </template>
              </div>

              <!--回答確認時 -->
              <div v-if="confirm">
                <span class="mr-2">回答：</span>
                <template v-if="qHandler.isTypeCheck(item)">
                  <div v-for="(selected, index) in qHandler.sortedSelectedList(item)" :key="index" class="pl-3">
                    {{ selected || item.otherText }}
                  </div>
                </template>
                <template v-else>
                  {{ qHandler.toDispChoiceText(item) }}
                </template>
              </div>

            </b-card>
          </b-row>

          <b-row class="my-5" v-if="!confirm">
            <b-col class="text-center">
              <b-button class="min-w-50 text-nowrap" @click="switchConfirm">
                <b-icon-exclamation-triangle-fill variant="warning" v-if="$v.$error"/>
                <span class="mx-2">回答確認へ進む</span>
              </b-button>
            </b-col>
          </b-row>

          <b-row class="my-5" v-if="confirm && !updated">
            <b-col class="px-0 text-center">
              <div>
                上記の内容で回答します。よろしいですか？
              </div>
              <div class="mt-3">
                <b-button variant="outline-secondary" class="min-w-25 mx-2" @click="confirm=false">戻る</b-button>
                <b-button variant="primary" class="min-w-25 mx-2" @click="updateData">
                  <b-spinner small v-if="sending"></b-spinner>回答する
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <div v-if="questionnaire.confirm_item.length == 0">
          <b-form-group class="my-4" label-cols-sm="2" label="本文" label-for="body">
            <b-textarea id="body" v-model="questionnaire.body" rows="4" max-rows="8" readonly="readonly"></b-textarea>
          </b-form-group>
          <b-row class="my-5" v-if="!updated">
            <b-col class="px-0 text-center">
              <div>
                上記の内容を確認済みとします。よろしいですか？
              </div>
              <div class="mt-3">
                <b-button variant="primary" class="w-50" @click="updateData">
                  <b-spinner small v-if="sending"></b-spinner>確認しました
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>

      <!-- 回答済み -->
      <b-row v-if="responsed || updated">
        <b-col class="px-0 text-center">
          <div id="completeMessage" class="text-nowrap">
            ご回答ありがとうございました。
          </div>
          <div class="mb-5" v-if="questionnaire.secret_token">
            <b-button variant="primary" class="min-w-25 mx-2" @click="moveToViewResponseSummary">
              集計結果を確認する<b-icon icon="box-arrow-up-right" class="ml-2" />
            </b-button>
          </div>
          <div>
            <b-button variant="outline-secondary" class="min-w-25 mx-2" v-if="isWindowClosable()" @click="windowClose">閉じる</b-button>
            <b-button variant="outline-secondary" class="min-w-25 mx-2" v-if="!updated" @click="clear">再回答する</b-button>
          </div>
        </b-col>
      </b-row>

    </div>
  </b-container>
</template>

<script>
import { requiredIf, maxLength } from 'vuelidate/lib/validators'

import { default as qHandler } from '@/const/questionnaireHandler'

export default {
  name: 'ViewForm',
  props: ['corpId', 'ruid'],
  data () {
    return {
      loading: false,
      sending: false,
      confirm: false,
      updated: false,
      responsed: false,
      userName: '',
      questionnaire: {
        confirm_item: [{choice: []}]
      },
      qHandler,
      error: null
    }
  },
  validations: {
    questionnaire: {
      confirm_item: {
        $each: {
          selected: {
            required: requiredIf(function (nestedModel) {
              if (qHandler.isTypeText(nestedModel)) {
                return false
              }
              const selected = Array.isArray(nestedModel.selected) ? 
                nestedModel.selected.length : nestedModel.selected || nestedModel.selected == ''
              return nestedModel.required && !selected
            })
          },
          otherText: {
            required: requiredIf(function (nestedModel) {
              if (qHandler.isTypeText(nestedModel)) {
                return nestedModel.required
              } else {
                return Array.isArray(nestedModel.selected) ? 
                  nestedModel.selected.includes('') : nestedModel.selected == ''
              }
            }),
            maxLength: function (value, nestedModel) {
              if (qHandler.isTypeText(nestedModel)) {
                return maxLength(200)(value)
              } else if (nestedModel.choice.length == 0 && nestedModel.selected == undefined 
                  || Array.isArray(nestedModel.selected) ? nestedModel.selected.includes('') : nestedModel.selected == '') {
                return maxLength(50)(value)
              }
              return true
            }
          }
        }
      }
    }
  },
  created () {
    this.fetchData()
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    clear () {
      this.sending = false
      this.confirm = false
      this.updated = false
      this.responsed = false
      this.error = null
    },

    fetchData () {
      this.loading = true
      this.clear()

      const params = {
        'corporation_id': this.corpId, 
        'ruid': this.ruid
      }
      this.$store.dispatch('http/get', { apiName: 'canaryPubViewForm', params: params }, { root: true })
        .then(res => {
          this.loading = false
          if (res.data) {
            this.userName = res.data.userName
            this.questionnaire = res.data.questionnaire
            if (res.data.questionnaireResult.responsed) {
              this.responsed = true
            }
          }
        })
        .catch(res => {
          this.questionnaire = {confirm_item: []}
          this.error = res.response
        })
    },

    switchConfirm () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }
      this.confirm = true
    },

    toChoiceText(v) {
      if (Array.isArray(v.selected)) {
        return v.selected.map(s => s || v.otherText)
      } else {
        return v.selected || (v.choice.length == 0 && v.selected == undefined || v.selected == '' ? v.otherText : undefined)
      }
    },

    updateData () {
      const newItems = this.questionnaire.confirm_item.map(v => ({...v, selected: this.toChoiceText(v)}))
      const params = {
        'corporation_id': this.corpId, 
        'ruid': this.ruid,
        'confirm_item': newItems
      }
      
      this.sending = true
      this.$store.dispatch('http/put', { apiName: 'canaryPubViewForm', data: params }, { root: true })
        .then(() => {
          this.updated = true
          const h = this.$createElement
          const messageVNode = h('div', { class: ['text-center'] }, [
            h('b-icon', { props: { icon: 'check-circle', fontScale: 4 }, style: { color: 'mediumseagreen', animation: 'pulse 2s infinite' } }),
            h('p', { class: ['text-center'], style: { fontSize: '1.2rem', margin: '10px 0 0' }}, '回答を送信しました')
          ])          
          this.msgBoxOk(messageVNode)
        })
        .catch(res => {
          this.error = res.response
        })
    },

    moveToViewResponseSummary () {
      // 別Windowで開く
      const resolvedRoute = this.$router.resolve({
        name: 'ViewResponseSummary', 
        params: { corpId: this.corpId, qId: this.questionnaire.questionnaire_id, secretToken: this.questionnaire.secret_token } 
      })
      window.open(resolvedRoute.href, '_blank')
    },

    isWindowClosable() {
      if (this.$route.query.app) {
        // アプリ（内部ブラウザ）からの遷移時には閉じるボタンが機能しないため、非表示とする
        return false
      }
      return window.opener || window === window.top && history.length <= 1
    },

    windowClose () {
      window.close()
    },
  }

}
</script>

<style>
/* Label is created dynamically and isn't scoped */
.choiceItem .custom-control-label {
  min-width: 100%;
  padding: .5rem 0 .5rem .2rem ;
}
.choiceItem .custom-control-label::before,
.choiceItem .custom-control-label::after {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
</style>
<style scoped>
#userName {
  font-size: 1.3rem;
  margin: .5rem;
}
#completeMessage {
  margin: 3rem 0 5rem 1rem;
  font-size: 1.4rem;
}
.otherText {
  border-radius: 0.25rem !important;
}
.choiceItem {
  padding: 0 .6rem;
}
.choiceItem:hover {
  background-color: aliceblue;
}
.choiceItem input[type="text"] {
  margin: 0.3em;
  height: 2em;
}
</style>