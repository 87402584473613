const handler = (() => {

  const Q_TYPE_RADIO = 'radio'
  const Q_TYPE_CHECK = 'check'
  const Q_TYPE_TEXT = 'text'
  
  const setType = (item, type) => {
    if (type == Q_TYPE_RADIO || type == Q_TYPE_CHECK) {
      item.type = type
      if (!item.choice.length) {
        item.choice = ['', '']
      }
    } else if (type == Q_TYPE_TEXT) {
      item.type = type
      item.choice.splice(0)
      item.choiceOther = false
    }
  }
  const isType = (item, type) => {
    if (item.type == type) {
      return true
    } else if (!item.type) {
      // 旧実装との互換のための制御
      const isText = item.choiceOther && item.choice.length == 0
      return !isText && type == Q_TYPE_RADIO || isText && type == Q_TYPE_TEXT
    }
    return false
  }
  
  return {
    setTypeRadio: (item) => setType(item, Q_TYPE_RADIO),
    setTypeCheck: (item) => setType(item, Q_TYPE_CHECK),
    setTypeText: (item) => setType(item, Q_TYPE_TEXT),
    isTypeRadio: (item) => isType(item, Q_TYPE_RADIO),
    isTypeCheck: (item) => isType(item, Q_TYPE_CHECK),
    isTypeText: (item) => isType(item, Q_TYPE_TEXT),
    choiceMinLength: (item) => {
      return isType(item, Q_TYPE_TEXT) || item.choice.length + (item.choiceOther ? 1 : 0) >= 2
    },
    toDispChoiceText: (item) => {
      if (Array.isArray(item.selected)) {
        return item.selected.map(s => s || item.otherText).join('，')
      } else if (item.selected) {
        return item.selected
      } else {
        return item.choice.length == 0 && item.selected == undefined || item.selected == '' ? item.otherText : undefined
      }
    },
    sortedSelectedList: (item) => {
      if (Array.isArray(item.selected)) {
        // 選択リスト（clone）を選択肢と同じ順番に並び替える
        // その他の選択肢は最後尾とする
        const max = item.choice.length
        return [...item.selected].sort((a, b) => (a && a != item.otherText ? item.choice.indexOf(a) : max) - (b && b != item.otherText ? item.choice.indexOf(b) : max))
      }
      return [item.selected != '' ? item.selected : item.otherText]
    }
  }
})()

export default handler